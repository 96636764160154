<template>

    <div style="width: 100%;display: flex;position: absolute;z-index: 999;background-color: #0F1F31;">

        <div style="width: 27%;text-align: center;">
            <img src="../../assets/logo1.png" alt="" style="margin-top: 10px;width: 110px;" >
        </div>
        <div style="width: 50%;">
            <ul>
                <li><a href="/#/">首页</a></li>
                <li><a href="/#/dongtai">大赛动态</a></li>
            </ul>
        </div>

        <div style="width: 23%;display: flex;justify-content: center;">
            <div
                style="margin-top: 18px;   display: flex;color: #fff;background-color: #2C6DF6;width: 110px;border-radius: 20px;height: 36px;justify-content: center; font-size: 14px;cursor: pointer;  align-items: center;" @click="toLogin">
                <div>登录</div>
                <div style="width: 20px;text-align: center;">|</div>
                <div>注册</div>
            </div>
        </div>
    </div>
</template>
<script>
import {toPath} from '@/config/env';
export default {
    name: "XjTop",
    data () {
        return {
            activeIndex: '1',
        }
    },
    computed: {
      getCurrentDate() {
        let yy = new Date().getFullYear();
        let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
        let dd = (new Date().getDate()) < 10 ? '0' + (new Date().getDate()) : (new Date().getDate());
        return  yy + '-' + mm + '-' + dd;
      }
    },
    methods: {
      toLogin(){
        /*if(this.getCurrentDate > '2024-07-31'){
          this.$message.warning("大赛报名截止时间为2024年7月31日");
          return false;
        }*/
        window.open(toPath);
      }
    },
    created () {

    },
}
</script>

<style scoped>
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        height: 62px;
        line-height: 62px;
    }

    li {
        float: left;
    }

    li  a{
        display: block;
        color: white;
        text-align: center;
        padding: 0px 20px;
        text-decoration: none;
    }

    li a:hover {
        background-color: royalblue;
    }


a {
    text-decoration: none !important;
    /* 去掉下划线 */
}
</style>
<style>
.el-menu.el-menu--horizontal {
    border-bottom: none !important;

}

.el-menu-item {
    height: 50px;
}

.el-menu--horizontal>.el-menu-item {
    height: 50px !important;
}
.el-menu {
    padding-left: 0 !important;
}
</style>
