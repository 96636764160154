<template>
    <div id="app">
        <div>
            <!-- 顶部 -->
            <Header></Header>

            <!-- 路由页面 -->
            <div class="box-card">
                <router-view/>
            </div>

            <!-- 底部 -->
            <div class="box-card" >
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>


<script>

    //引入组件
    import Header from './page/top/header';
    import Footer from './page/bottom/footer';

    export default {
        name: "app",
        //定义组件
        components: {
            Header: Header,
            Footer: Footer,
        }
    }


</script>

<style>
  /*  #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    nav {
        padding: 30px;
    }

    nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    nav a.router-link-exact-active {
        color: #42b983;
    } */
</style>
