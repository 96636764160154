<template>
    <div class="user-bottom">
      Copyright © 2024&nbsp;
      <a href="https://beian.miit.gov.cn/" style="color:#FFFFFF;text-decoration:none;" target="_blank">ICP备案：黔ICP备2023010235号-1</a>&nbsp;
      <a target="_blank" href="http://www.beian.gov.cn" style="display:inline-block;text-decoration:none;height:20px;line-height:26px;"><img src="../../assets/beian/beian.png" style="float:left;padding-top: 5px;"/><p style="float:left;height:20px;line-height:26px;margin: 0px 0px 0px 5px; color:#FFFFFF;">贵公网安备：52010302003507号</p></a>
    </div>

</template>

<script>
    export default {
        name: "XjBottom.vue"
    }
</script>


<style scoped>
    .user-bottom{
      background-color: #0F1F31;
      height: 72px;
      line-height: 72px;
      text-align: center;
      color: #FFFFFF;
    }

</style>
