// 请求地址 (本地, 部署请使用 nginx 转发)
// let basePath = "http://localhost:6868/";
let basePath = "http://ck2024.czyone.com/api";//正式环境

// let toPath = "http://localhost:8080/#/login";
let toPath = "http://ck2024.czyone.com/ck/#/login";//正式环境

module.exports = {
    basePath,
    toPath
};
