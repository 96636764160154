import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'home',
        // component: HomeView
        component: () => import( /* webpackChunkName: "about" */ '../views/home/index.vue')
    },
    {
        path: '/dongtai',
        name: 'dongtai',
        // component: HomeView
        component: () => import( /* webpackChunkName: "about" */ '../views/dongtai/index.vue')
    },
    {
        path: '/detail',
        name: 'detail',
        // component: HomeView
        component: () => import( /* webpackChunkName: "about" */ '../views/dongtai/detail.vue')
    },
  

]

const router = new VueRouter({
    routes
})

export default router